import { LinemodeEnum } from '../../../api/opoint-search-suggest.schemas'

export type QueryObject = ProfileItem & {
  language: QueryLanguage
}

export type QuerySearchline = QueryObject['searchline']

export type QueryLanguage = (typeof QueryLanguage)[keyof typeof QueryLanguage]
export const QueryLanguage = {
  IQL: 'IQL',
  OQL: 'OQL',
} as const

export type LineModeFieldName = (typeof LineModeFieldName)[keyof typeof LineModeFieldName]
export const LineModeFieldName = {
  [LinemodeEnum.R]: 'queries.required',
  [LinemodeEnum.O]: 'queries.optional',
  [LinemodeEnum.E]: 'queries.exclude',
} as const

export type QueryFieldName = `${LineModeFieldName}.${number}` | 'queries.compare.0'

export type ProfileItemFilter = {
  id?: string
  type?: string
  name?: string
}

//TODO: generated type is incorrect
export type ProfileItem = {
  id?: number
  searchline: {
    searchmode?: string
    searchterm?: string
    filters: ProfileItemFilter[]
  }
  linemode: LinemodeEnum
}

export enum InfoSheetTabs {
  Basic = 'OQL',
  Expert = 'IQL',
  Metadata = 'Metadata',
}

export enum ProfileBuilderPeriod {
  LastDay = 'd',
  LastWeek = 'w',
  LastMonth = 'm',
  LastYear = 'y',
}
