import { createSelector } from 'reselect'

import { assoc, map as mapR, merge, path, pick } from 'ramda'
import { StoredSearchType } from '../components/types/profile'
import type { ProfileDetail, ProfileHistoryFromApi, Searchline } from '../opoint/flow'

import { FilterSuggestion } from '../api/opoint-search-suggest.schemas'
import { getForm, getProfiles as getState } from './rootSelectors'

export const getProfiles = createSelector(getState, (profilesState) => profilesState.list)

export const getProfilesWithType = createSelector(getProfiles, (list) => mapR(assoc('type', 'profile'), list))

export const getOpenProfile = createSelector(getState, (profilesState) => profilesState.editedProfile)

export const getProfileItemsWithDebug = createSelector(getState, (profileState) => profileState.profileItemsWithDebug)

export const getRootProfiles = createSelector(getProfiles, (profilesList) =>
  profilesList.filter((profile) => profile.parent === 0),
)

export const getProfileById = (profileId: number) =>
  createSelector(getProfiles, (profiles) => profiles.find(({ id }) => profileId === id))

export const getEditedProfile = createSelector(getState, getForm, (profilesState, profileForm): ProfileDetail => {
  if (!profilesState.editedProfile) {
    // @ts-expect-error: Muted so we could enable TS strict mode
    return null
  }

  const {
    // The redux-form is pretty wild to type.
    // This selector will most likely get removed during redux-form removal: https://infomediacorp.atlassian.net/browse/FE-10107
    // @ts-expect-error: Muted so we could enable TS strict mode
    profileEditor: { values: { profileName, profileParent, storedSearch, sm_rate, profileFolder, blessed } = {} } = {},
  } = profileForm
  // If user don't have the rights to store search, just return normal object to him
  if (profilesState.editedProfile.stored_search === undefined) {
    return (
      profilesState.editedProfile &&
      merge(profilesState.editedProfile, {
        name: profileName,
        parent: profileParent,
        sm_rate,
        folder: profileFolder,
        blessed,
      })
    )
  }
  const newStoredSearch =
    profilesState.editedProfile.stored_search === null
      ? storedSearch
        ? { active: -1 }
        : null // If they didn't setup stored_search yet, don't
      : { active: storedSearch ? -1 : 0 } // -1 to activate it, 0 to disable it

  return (
    profilesState.editedProfile &&
    merge(profilesState.editedProfile, {
      name: profileName,
      parent: profileParent,
      stored_search: newStoredSearch as StoredSearchType,
    })
  )
})

export const getIsProfileEditorFiltersPanelOpen = createSelector(getState, (state) => state.profileEditorFiltersOpen)

export const getSearchDataForSearchline = (lineNumber: number) =>
  createSelector(
    getEditedProfile,
    (editedProfile): Searchline => !!editedProfile?.items && editedProfile.items[lineNumber]?.searchline,
  )

export const getProfilesToDelete = createSelector(getState, (profiles) => profiles.profilesToDelete)

export const getPreviewArticles = createSelector(getState, (profilesState) => profilesState.profileEditorArticles)

export const getPreviewActiveArticle = createSelector(
  getState,
  (profilesState) => profilesState.profileEditorActiveArticle,
)

export const getPreviewIdentical = createSelector(
  getState,
  (profilesState) => profilesState.profileEditorArticlesIdentical,
)

export const getSaveAsProfile = createSelector(getForm, path(['profile', 'saveAs', 'values']))

export const getActiveProfileEditorLine = createSelector(getState, (profiles) => profiles.activeProfileEditorLine)

export const getClickedFilterName = createSelector(getState, (profiles) => profiles.clickedFilterName)

export const getClickedFilterType = createSelector(getState, (profiles) => profiles.clickedFilterType)

export const getFilterMetadata = createSelector(getState, (profiles) => profiles.filterMetadata)

export const getFilterMetadataFetchStatus = createSelector(getState, (profiles) => profiles.filterMetadataFetchStatus)

export const isFilterMetadataFetchInProgress = createSelector(
  getState,
  (profiles) => profiles.filterMetadataFetchInProgress,
)

export const getIsProfileHistoryExpanded = createSelector(getState, (profiles) => profiles.profileHistoryExpanded)

export const getIsDeletedProfilesExpanded = createSelector(getState, (profiles) => profiles.deletedProfilesExpanded)

/**
 * Provide profile history without searchline for component render
 */
export const getProfileHistory = createSelector(getState, (profiles) => {
  const profileHistory = profiles.editedProfile?.history
  if (profileHistory && profileHistory.count === 0) {
    return { results: [], count: 0 }
  }

  return (
    profileHistory &&
    (assoc(
      'results',
      mapR(pick(['name', 'timestamp']), profileHistory.results),
      profileHistory,
    ) as ProfileHistoryFromApi)
  )
})

export const getProfileTree = createSelector(getState, (profiles) => profiles.tree)

export const getShowMoreHistorySegment = createSelector(getState, (profiles) => profiles.showMoreHistorySegment)

export const getAllHistoryResultsLength = createSelector(
  getState,
  (profiles) =>
    profiles.editedProfile &&
    profiles.editedProfile.allHistoryResults &&
    profiles.editedProfile.allHistoryResults.length,
)

export const getInlineSuggestions = (id: number) =>
  createSelector(getState, (profiles) => profiles.profileEditorInlineSuggestions[id] ?? ([] as FilterSuggestion[]))

//TODO: see if we can use this in useArticleBaseProps and streamline the logic
// (as part of Profile Reducer migration)
//https://infomediacorp.atlassian.net/browse/FE-11315
export const getIsPreviewOpened = createSelector(getState, (profiles) => profiles.isProfilePreviewOpened)

export const hasPreviewArticles = createSelector(getState, (profiles) => profiles.profileEditorArticles.length > 0)

export const getIsProfileEditorShownInPreview = createSelector(
  getState,
  (profiles) => profiles.isProfileEditorShownInPreview,
)

export const hasEditedProfileSoMeFilters = createSelector(
  getState,
  (profiles) =>
    profiles.editedProfile &&
    profiles.editedProfile.items &&
    profiles.editedProfile.items.some((item) => {
      const { linemode, searchline } = item

      return (
        linemode === 'R' &&
        searchline.filters.some(({ name }) => {
          // @ts-expect-error: Muted so we could enable TS strict mode
          return ['Facebook', 'Twitter', 'Instagram'].includes(name)
        })
      )
    }),
)

export const getIsProfileEditorSaveInProgress = createSelector(
  getState,
  (profiles) => profiles.profileEditorSaveInProgress,
)

export const getIsProfileSaveAsInProgress = createSelector(getState, (profiles) => profiles.profileSaveAsInProgress)

export const getIsLoadingPreview = createSelector(getState, (profiles) => profiles.isLoadingPreview)

export const getProfileBuilderCompareHistoryVersion = createSelector(
  getState,
  (profiles) => profiles.profileBuilderCompareHistoryVersion,
)

export const getIsProfileBuilderCurrentHistoryVersion = createSelector(
  getState,
  (profiles) => profiles.isProfileBuilderCurrentHistoryVersion,
)
