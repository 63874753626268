import { format } from 'date-fns'
import { produce } from 'immer'
import { getArticleId } from '@opoint/infomedia-storybook'

import {
  __,
  compose,
  evolve,
  filter,
  groupBy,
  isEmpty,
  join,
  map as mapR,
  pick,
  prop,
  propEq,
  reduce,
  sort,
  sortBy,
  sum,
  toPairs,
  values,
} from 'ramda'
import { AppActions } from '../actions'
import { FilterSuggestion, ProfileItem } from '../api/opoint-search-suggest.schemas'
import { Dependencies, Profile, ProfileWithChildren } from '../components/types/profile'
import { identicalReducer, preprocessArticles } from '../opoint/articles'
import {
  CommonFilterMeta,
  CommonFilterMetaDetails,
  ProfileDetail,
  SearchItem,
  SetActiveArticle,
  SiteFilterMetaDetails,
  SiteMetasProps,
  SuggestionForFilterDrilldown,
  Suggestions,
  TBList,
  TopicLangSiteSubject,
} from '../opoint/flow'
import { profileHistorySegmentation, profileListToProfileTree } from '../opoint/profiles'
import { eqFilters, filterId, invertFilter } from '../opoint/search'

export type ProfilesState = {
  list: Array<Profile>
  tree: Array<ProfileWithChildren>
  editedProfile: ProfileDetail | null
  activeProfileEditorLine: number
  // each profile line maps to an object of
  // TODO make this a special flow type and merge with suggestions from reducers/search.js
  profileEditorInlineSuggestions: { [key: string]: Array<FilterSuggestion> }
  profileEditorArticles: Array<any>
  profileEditorActiveArticle: SetActiveArticle
  profileEditorArticlesIdentical: { [key: string]: number }
  profileEditorSuggestions: { [key: string]: Suggestions }
  profileEditorFiltersOpen: boolean
  profileEditorSaveInProgress: boolean
  profileSaveAsInProgress: boolean
  clickedFilterName: string
  filterMetadata: CommonFilterMeta[] | SiteMetasProps[] | TBList | null
  filterMetadataFetchInProgress: boolean
  filterMetadataFetchStatus: number
  clickedFilterType: string
  profilesToDelete: Array<number>
  profileHistoryExpanded: boolean
  deletedProfilesExpanded: boolean
  showMoreHistorySegment: number
  isProfilePreviewOpened: boolean
  isProfileEditorShownInPreview: boolean
  profilesToDeleteDeps: {
    [key: number]: {
      deps: Dependencies
      id: number
    }
  }
  profileEditorFiltersShowMore: SuggestionForFilterDrilldown[]
  isLoadingPreview: boolean
  profileItemsWithDebug: Array<SearchItem>
  profileBuilderCompareHistoryVersion: ProfileItem[] | null
  isProfileBuilderCurrentHistoryVersion: boolean
}

export const initialState: ProfilesState = {
  activeProfileEditorLine: 0,
  clickedFilterName: '',
  clickedFilterType: '',
  profilesToDeleteDeps: [],
  deletedProfilesExpanded: false,
  editedProfile: null,
  filterMetadata: null,
  filterMetadataFetchInProgress: false,
  filterMetadataFetchStatus: 0,
  list: [],
  // @ts-expect-error: Muted so we could enable TS strict mode
  profileEditorActiveArticle: { index: null, source: null },
  profileEditorArticles: [],
  profileEditorArticlesIdentical: {},
  profileEditorFiltersOpen: false,
  profileEditorInlineSuggestions: {},
  profileEditorSaveInProgress: false,
  profileSaveAsInProgress: false,
  profileEditorSuggestions: {},
  profileEditorFiltersShowMore: [],
  profileHistoryExpanded: false,
  profilesToDelete: [],
  showMoreHistorySegment: 1,
  tree: [],
  isProfilePreviewOpened: false,
  isProfileEditorShownInPreview: false,
  isLoadingPreview: false,
  profileItemsWithDebug: [],
  profileBuilderCompareHistoryVersion: null,
  isProfileBuilderCurrentHistoryVersion: false,
}

// TODO move this to Opoint libs BEGIN
const isSearchlineEmpty = ({ searchline: { searchterm, filters } }: SearchItem) =>
  isEmpty(searchterm) && isEmpty(filters)

const emptyProfileLine = (linemode) => ({
  searchline: {
    searchterm: '',
    filters: [],
  },
  linemode,
})
// TODO move this to Opoint libs END

/**
 * Transforms gotten api data to array of categories which conteins title
 * of category and array of corresponding data;
 * Sorts rank categoty by ranks (not by count as default);
 * Filters required categories
 */
// @ts-expect-error: Muted so we could enable TS strict mode
export const metadataOrganization = compose(
  mapR(([categoryName, categoryContent]) => ({
    title: categoryName,
    content: categoryContent,
  })),
  toPairs,
  evolve({
    Rank: sortBy(prop('subjectName')),
  }),
  pick(['Rank', 'Speaker', 'Location', 'Format']),
  groupBy((topic: TopicLangSiteSubject) => topic.metacatName),
)

export const siteDatesOrganization = compose(
  // @ts-expect-error: Muted so we could enable TS strict mode
  mapR((datePair) => [format(new Date(datePair[0]), 'do MMM'), datePair[1]]),
  sort((a, b) => {
    const aDate = new Date(a[0]).valueOf()
    const bDate = new Date(b[0]).valueOf()

    return bDate - aDate
  }),
  toPairs,
)

export const totalArticlesCount = compose(sum, values)

export const siteRank = compose(join(', '), mapR(prop('subjectName')), filter(propEq('metacatName', 'Rank')))

export const profilesReducer = produce((draftState: ProfilesState, action: AppActions) => {
  switch (action.type) {
    case 'LOGOUT': {
      draftState = initialState
      break
    }

    case 'PROFILES_FETCH_SUCCESS': {
      const profiles = action.payload

      const expandedProfiles = draftState.list.filter((profile) => profile.expanded)

      if (expandedProfiles.length > 0) {
        // Use a Set to store IDs of expanded profiles for quick lookup
        const expandedProfileIds = new Set(expandedProfiles.map((profile) => profile.id))

        // Update profiles to mark them expanded if they were expanded before
        profiles.forEach((profile) => {
          if (expandedProfileIds.has(profile.id)) {
            profile.expanded = true
          }
        })
      }

      // Construct the new tree based on the updated profiles list
      const updatedTree = profileListToProfileTree(profiles) // This function should construct a tree of type `ProfileWithChildren[]`

      // Update draft state directly
      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.tree = updatedTree // updatedTree should be of type `ProfileWithChildren[]`
      draftState.list = profiles // profiles remains an array of flat `Profile` objects

      break
    }

    case 'LOAD_EDITED_PROFILE_SUCCESS':
      draftState.profileHistoryExpanded = false
      draftState.deletedProfilesExpanded = false
      draftState.showMoreHistorySegment = 1
      draftState.editedProfile = action.payload
      draftState.activeProfileEditorLine = 0
      draftState.profileEditorArticlesIdentical = {}
      draftState.profileEditorArticles = []
      break

    case 'SEARCHTERM_CHANGED_PROFILE_EDITOR': {
      const { id, text } = action.payload

      if (draftState.editedProfile?.items[id]) {
        draftState.editedProfile.items[id].searchline.searchterm = text
      }
      break
    }

    case 'PROFILE_EDITOR_INVALID_SEARCHLINE': {
      const { debug } = action.payload

      const profileItems = draftState.editedProfile && draftState.editedProfile.items
      const lines = debug?.lines
      const newSearchLinesWithDebug: SearchItem[] = lines
        ? (profileItems || [])
            .map((item, index) => ({ lineIndex: index, ...item }))
            .filter((item) => !!item.searchline.searchterm)
            .map((item, index) => ({ line: lines[index], ...item }))
        : []

      draftState.profileEditorSaveInProgress = false
      draftState.profileItemsWithDebug = newSearchLinesWithDebug
      break
    }

    case 'PROFILE_EDITOR_CLEAR_DEBUG':
      draftState.profileItemsWithDebug = []
      break

    case 'ADD_PROFILE_EDITOR_LINE':
      draftState.editedProfile?.items.push(emptyProfileLine(action.payload))
      break

    case 'PROFILE_EDITOR_FOCUSED_LINE_CHANGED':
      draftState.activeProfileEditorLine = action.payload.id ?? 0
      break

    case 'PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_SUCCESS': {
      const { id, results } = action.payload
      draftState.profileEditorSuggestions[id] = results
      break
    }

    case 'PROFILE_EDITOR_FILTERS_FETCH_MULTIPLE_OF_TYPE_SUCCESS':
      draftState.profileEditorFiltersShowMore = [action.payload]
      break

    case 'LEAVE_PROFILE_EDITOR':
      draftState.profileEditorSaveInProgress = false
      draftState.profileHistoryExpanded = false
      draftState.deletedProfilesExpanded = false
      draftState.showMoreHistorySegment = 1
      draftState.editedProfile = null
      draftState.activeProfileEditorLine = 0
      draftState.profileEditorArticlesIdentical = {}
      draftState.profileEditorArticles = []
      draftState.profileEditorFiltersOpen = false
      draftState.isProfilePreviewOpened = false
      break

    case 'FILTER_METAS_SHOW_DETAIL':
      draftState.clickedFilterType = action.payload.filter.type
      draftState.clickedFilterName = action.payload.filter.name ?? ''
      break

    case 'FILTER_METAS_HIDE_DETAIL':
      draftState.clickedFilterName = ''
      draftState.filterMetadata = null
      draftState.filterMetadataFetchStatus = 0
      break

    case 'FILTER_METAS_FETCH':
      draftState.filterMetadata = null
      draftState.filterMetadataFetchInProgress = true
      draftState.filterMetadataFetchStatus = 0
      break

    case 'FILTER_METAS_FETCH_SUCCESS': {
      const { filterMetadata, name, type } = action.payload
      let processedMetas
      switch (type) {
        case 'list':
        case 'tblist':
          processedMetas = filterMetadata
          break
        case 'site':
          processedMetas = (filterMetadata as SiteFilterMetaDetails[])?.map((filter) => ({
            sortedDates: siteDatesOrganization(filter.metas.countsByDate),
            rank: siteRank(filter.metas.siteSubject),
            site: filter.metas.site,
            totalCountLastDays: totalArticlesCount(filter.metas.countsByDate),
          }))
          break
        case 'lang':
          processedMetas = metadataOrganization((filterMetadata as CommonFilterMetaDetails).metas.lang)
          break
        default:
          processedMetas = metadataOrganization((filterMetadata as CommonFilterMetaDetails).metas.topic)
      }

      draftState.clickedFilterName = name
      draftState.clickedFilterType = type
      draftState.filterMetadata = processedMetas
      draftState.filterMetadataFetchInProgress = false
      draftState.filterMetadataFetchStatus = 200
      break
    }

    case 'FILTER_METAS_FETCH_FAILURE':
      draftState.clickedFilterName = ''
      draftState.filterMetadata = null
      draftState.filterMetadataFetchInProgress = false
      draftState.filterMetadataFetchStatus = action.payload.status
      break

    case 'PROFILE_EDITOR_SAVE_PROFILE':
      draftState.profileEditorSaveInProgress = true
      break

    case 'PROFILE_EDITOR_SAVE_PROFILE_FAILURE':
      draftState.profileEditorSaveInProgress = false
      break

    case 'FETCH_MORE_PREVIEW_ARTICLES_SUCCESS': {
      const interceptedDocuments = preprocessArticles(action.payload.response.searchresult.document)

      draftState.profileEditorArticlesIdentical = action.payload.response.searchresult.document.reduce(
        identicalReducer,
        draftState.profileEditorArticlesIdentical,
      )

      const currentArticles = new Map(
        draftState.profileEditorArticles.map((article) => [getArticleId(article), article]),
      )

      interceptedDocuments.forEach((article) => {
        const id = getArticleId(article)
        if (!currentArticles.has(id)) {
          currentArticles.set(id, article)
        }
      })

      draftState.profileEditorArticles = Array.from(currentArticles.values())

      break
    }

    case 'PROFILE_EDITOR_PREVIEW_CLEAR':
      draftState.profileEditorArticlesIdentical = {}
      draftState.profileEditorArticles = []
      draftState.isProfilePreviewOpened = false
      draftState.isProfileEditorShownInPreview = false
      break

    case 'PROFILE_EDITOR_PREVIEW_TOGGLE_EDITOR':
      draftState.isProfileEditorShownInPreview = !draftState.isProfileEditorShownInPreview
      break

    case 'PROFILE_EDITOR_PREVIEW_SUCCESS': {
      // TODO: Write test for this action
      const {
        searchresult: { document },
      } = action.payload
      if (!document || document.length === 0) {
        draftState.profileEditorArticlesIdentical = {}
        draftState.profileEditorArticles = []
        draftState.isProfilePreviewOpened = true
        draftState.isLoadingPreview = false
      }

      // TODO: Investigate why are articles processed differently in different parts of the application
      // https://infomediacorp.atlassian.net/browse/FE-10066

      const interceptedDocuments = preprocessArticles(document)

      draftState.profileEditorArticlesIdentical = reduce(identicalReducer, __, document)
      draftState.profileEditorArticles = interceptedDocuments
      draftState.profileEditorActiveArticle = { index: 0, source: 'click' }
      draftState.isProfilePreviewOpened = true
      draftState.isLoadingPreview = false
      break
    }

    case 'DELETE_PROFILES_MODE_TOGGLE':
    case 'MANAGE_PROFILES_MODAL_CLOSE':
      draftState.profilesToDelete = []
      break

    case 'PROFILE_EDITOR_PREVIEW':
      draftState.isLoadingPreview = true
      break

    case 'PROFILE_MARK_FOR_DELETE_MODE': {
      const id = action.payload
      if (draftState.profilesToDelete.includes(id)) {
        // ID was found -> remove the id from profilesToDelete
        draftState.profilesToDelete = draftState.profilesToDelete.filter((profileId) => profileId !== id)
      } else {
        // No ID found -> add the id to profilesToDelete
        draftState.profilesToDelete.push(id)
      }
      break
    }

    case 'PROFILE_DELETE_SUCCESS': {
      draftState.list = draftState.list.filter((profile) => profile.id !== action.payload)
      draftState.profilesToDelete = draftState.profilesToDelete.filter((profileId) => profileId !== action.payload)
      if (draftState.editedProfile && draftState.editedProfile.id === action.payload) {
        draftState.editedProfile = null
      }
      break
    }

    case 'SAVE_AS_PROFILE':
      draftState.profileSaveAsInProgress = true
      break

    case 'SAVE_AS_PROFILE_FAILURE':
    case 'SAVE_AS_PROFILE_SUCCESS':
      draftState.profileSaveAsInProgress = false
      break

    case 'PROFILES_GET_DEPENDENCIES_SUCCESS': {
      action.payload?.forEach((dependency) => {
        dependency.deps.profiles = draftState.list.filter(({ id }) =>
          dependency.deps.profiles?.some(({ id: profileId }) => profileId === id),
        )
      })

      draftState.profilesToDeleteDeps = Object.fromEntries(action.payload.map((dep) => [dep.id, dep]))
      break
    }

    case 'PROFILE_EDITOR_FILTERS_TOGGLE':
      draftState.profileEditorFiltersOpen = !draftState.profileEditorFiltersOpen
      break

    case 'PROFILE_EDITOR_FILTER_REMOVED': {
      const { id, filter } = action.payload

      if (draftState.editedProfile) {
        const filters = draftState.editedProfile.items[id].searchline.filters

        draftState.editedProfile.items[id].searchline.filters = filters.filter(
          (existingFilter) => !eqFilters(filter)(existingFilter),
        )
      }

      break
    }

    case 'PROFILE_EDITOR_FILTERS_FETCH_SIMPLE_SUCCESS': {
      const { id, results } = action.payload
      draftState.profileEditorInlineSuggestions = { [id]: results }
      break
    }

    case 'PROFILE_EDITOR_FILTER_INVERTED': {
      const { id, filter } = action.payload
      const filters = draftState.editedProfile?.items[id].searchline.filters

      if (!filters || !draftState.editedProfile) break

      draftState.editedProfile.items[id].searchline.filters = filters.map((existingFilter) =>
        eqFilters(filter)(existingFilter) ? invertFilter(existingFilter) : existingFilter,
      )

      break
    }

    case 'PROFILE_EDITOR_SEARCHFILTER_TOGGLE': {
      const filter = action.payload

      if (!draftState.editedProfile?.items[draftState.activeProfileEditorLine]) {
        break // Exit early if no valid activeProfileEditorLine exists
      }

      // Get the active profile line to edit from draftState
      const activeLineIndex = draftState.activeProfileEditorLine
      const activeLineItem = draftState.editedProfile.items[activeLineIndex]

      // Access filters for the current searchline
      const filters = activeLineItem.searchline.filters

      // Find the index of the filter to toggle
      const filterIndex = filters.findIndex((f) => filterId(f) === filterId(filter))

      if (filterIndex !== -1) {
        // If the filter is found, remove it
        filters.splice(filterIndex, 1)
      } else {
        // If the filter is not found, add it
        filters.push(filter)
      }

      break
    }

    case 'SEARCHDATA_CLEAR_PROFILE_EDITOR': {
      const id = action.payload.id

      if (draftState.editedProfile?.items[id]) {
        const item = draftState.editedProfile.items[id]

        if (isSearchlineEmpty(item)) {
          // Remove the item if the searchline is empty
          draftState.editedProfile.items.splice(id, 1)
        } else {
          // Clear the searchline if it contains data
          draftState.editedProfile.items[id].searchline.searchterm = ''
          draftState.editedProfile.items[id].searchline.filters = []
        }
      }

      break
    }

    case 'NEXT_PROFILE_EDITOR_PREVIEW_ACTIVE_ARTICLE': {
      // Select next article to preview on keyPress
      const maxIndex = draftState.profileEditorArticles.length - 1
      draftState.profileEditorActiveArticle.index = Math.min(draftState.profileEditorActiveArticle.index + 1, maxIndex)
      draftState.profileEditorActiveArticle.source = 'keyPress'
      break
    }

    case 'PREVIOUS_PROFILE_EDITOR_PREVIEW_ACTIVE_ARTICLE':
      draftState.profileEditorActiveArticle.index = Math.max(draftState.profileEditorActiveArticle.index - 1, 0)
      draftState.profileEditorActiveArticle.source = 'keyPress'
      break

    case 'SET_ACTIVE_PROFILE_EDITOR_PREVIEW_IDENTICAL':
      draftState.profileEditorArticlesIdentical[getArticleId(action.payload.article)] = action.payload.index
      break

    case 'SET_ACTIVE_PROFILE_EDITOR_PREVIEW_ARTICLE':
      draftState.profileEditorActiveArticle = {
        index: Math.max(0, Math.min(draftState.profileEditorArticles.length, action.payload.index)),
        source: action.payload.source,
      }
      break

    case 'PROFILE_SHOW_HISTORY':
      draftState.profileHistoryExpanded = true
      draftState.deletedProfilesExpanded = false
      break

    case 'DELETED_PROFILES_EXPAND':
      draftState.profileHistoryExpanded = false
      draftState.deletedProfilesExpanded = true
      break

    case 'PROFILE_HISTORY_SHOW_MORE': {
      if (draftState.editedProfile?.history) {
        const allHistoryResults = draftState.editedProfile?.allHistoryResults || []
        const currentSegmentIndex = draftState.showMoreHistorySegment

        if (currentSegmentIndex < allHistoryResults.length) {
          const addSegment = allHistoryResults[currentSegmentIndex] || []
          const currentResults = draftState.editedProfile?.history?.results || []

          draftState.editedProfile.history.results = [...currentResults, ...addSegment]

          draftState.showMoreHistorySegment += 1
        }
      }
      break
    }

    case 'PROFILE_HIDE_HISTORY':
      draftState.profileHistoryExpanded = false
      draftState.deletedProfilesExpanded = false
      draftState.showMoreHistorySegment = 1
      if (draftState.editedProfile) {
        draftState.editedProfile.history = undefined
      }
      break

    case 'PROFILE_EDITOR_BACK_TO_SEARCH':
      draftState.isProfileEditorShownInPreview = false
      draftState.showMoreHistorySegment = 1
      draftState.profileHistoryExpanded = false
      draftState.deletedProfilesExpanded = false
      draftState.isProfileEditorShownInPreview = false
      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.editedProfile.history = null
      break

    case 'PROFILE_HISTORY_FETCH_FAILURE':
      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.editedProfile.history = { count: 0, results: [] }
      // @ts-expect-error: Muted so we could enable TS strict mode
      draftState.editedProfile.allHistoryResults = []
      break

    case 'PROFILE_HISTORY_FETCH_SUCCESS':
    case 'DELETED_PROFILES_HISTORY_FETCH_SUCCESS': {
      const {
        history: { count, results },
      } = action.payload
      const processedResults = profileHistorySegmentation(results)

      if (draftState.editedProfile) {
        if (!draftState.editedProfile.history) {
          // @ts-expect-error: Muted so we could enable TS strict mode
          draftState.editedProfile.history = {}
        }

        // @ts-expect-error: Muted so we could enable TS strict mode
        draftState.editedProfile.history.count = count
        // @ts-expect-error: Muted so we could enable TS strict mode
        draftState.editedProfile.history.results = processedResults[0]

        if (!draftState.editedProfile.allHistoryResults) {
          draftState.editedProfile.allHistoryResults = []
        }
        draftState.editedProfile.allHistoryResults.push(...processedResults)
      }
      break
    }

    case 'NEXT_PROFILE_EDITOR_PREVIEW_IDENTICAL': {
      const identicalArticles = action.payload.identical_documents?.document
      const articleKey = getArticleId(action.payload)

      // Initialize if not present
      if (!(articleKey in draftState.profileEditorArticlesIdentical)) {
        draftState.profileEditorArticlesIdentical[articleKey] = 0
      }

      if (identicalArticles) {
        draftState.profileEditorArticlesIdentical[articleKey] =
          (draftState.profileEditorArticlesIdentical[articleKey] + 1) % identicalArticles.length
      }
      break
    }

    case 'PROFILE_SET_OLD_VERSION': {
      const { timestamp } = action.payload

      // Find the profile with the specified timestamp
      // @ts-expect-error: Muted so we could enable TS strict mode
      const historyResults = draftState.editedProfile.history.results
      const foundItem = historyResults.find((profile) => profile.timestamp === timestamp)

      if (foundItem) {
        // Remove the `id` from each filter
        const newItems = foundItem.items.map((item) => {
          const newItem = { ...item }
          delete newItem.id
          return newItem
        })

        // @ts-expect-error: Muted so we could enable TS strict mode
        draftState.editedProfile.items = newItems
      }
      break
    }

    case 'PREVIOUS_PROFILE_EDITOR_PREVIEW_IDENTICAL': {
      const { identical_documents, ...payloadArticle } = action.payload
      const identicalArticles = identical_documents?.document
      const articleKey = getArticleId(payloadArticle)

      if (!draftState.profileEditorArticlesIdentical[articleKey]) {
        draftState.profileEditorArticlesIdentical[articleKey] = 0
      }

      const currentIndex = draftState.profileEditorArticlesIdentical[articleKey]
      if (identicalArticles) {
        const newIndex = (currentIndex - 1 + identicalArticles.length) % identicalArticles.length

        draftState.profileEditorArticlesIdentical[articleKey] = newIndex
      }
      break
    }

    case 'PROFILE_EXPAND_CHILDREN': {
      const id = action.payload

      // Find the index of the item with the given id
      const idx = draftState.list.findIndex((item) => item.id === id)

      if (idx !== -1) {
        // Toggle the expanded property of the found item
        draftState.list[idx].expanded = !draftState.list[idx].expanded
      }

      break
    }

    case 'SET_PROFILE_BUILDER_COMPARE_HISTORY_VERSION': {
      draftState.profileBuilderCompareHistoryVersion = action.payload

      break
    }

    case 'SET_PROFILE_BUILDER_COMPARE_CURRENT_HISTORY_VERSION': {
      draftState.isProfileBuilderCurrentHistoryVersion = action.payload

      break
    }
  }
}, initialState)

export default profilesReducer
